import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Gymnastic Day`}</em></p>
    <p>{`Parallel Bar Dips 6×4 (weighted if possible)`}</p>
    <p>{`Neutral Grip Pullups 6×4 (weighted if possible)`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time:`}</p>
    <p>{`12-Pushups (RX+ Clapping Pushups)`}</p>
    <p>{`14-Bodyweight Rows (Lying underneath barbell racked at hip height)`}</p>
    <p>{`16/12-Calorie Assault Bike`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      